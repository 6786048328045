#wrapper{
    width: 100%;
    height: 100%;
    position: relative;
    #downBox{
        width: 100%;
        height: 100%;
        .section:nth-child(1){
            width: 100%;
            height: 100%;
            background: url(../assets/images/index/index.png) no-repeat;
            background-size: 100% 100%;
            .startCustomizationBtn{
                width: 210px;
                height: 60px;
                background: url(../assets/images/index/start.png) no-repeat;
                background-size: 100% 100%;
                position: absolute;
                bottom: 36%;
                left: 50%;
                margin-left: -105px;
                cursor: pointer;
            }
            .downArrow{
                width: 35px;
                height: 35px;
                background: url(../assets/images/index/arrow.png) no-repeat;
                background-size: 100% 100%;
                position: absolute;
                bottom: 5%;
                left: 50%;
                margin-left: -17.5px;
                animation: btnarrow .5s linear .3s infinite;
            }
            @keyframes btnarrow{
                0% {
                    opacity: 1;
                    transform-origin: center center;
                    bottom: 42px;
                }
                50% {
                    opacity: .5;
                    transform-origin: center center;
                    bottom: 36px;
                }
                100% {
                    opacity: 1;
                    transform-origin: center center;
                    bottom: 42px;
                }
            }
        }
        .section:nth-child(2){
            width: 100%;
            height: 100%;
            >div{
                height: 100%;
                vertical-align: top!important;
                .terminalEquipmentTitle{
                    margin-top:80px;
                    text-align: center;
                    p{
                        margin-bottom: 20px;
                    }
                    >p:nth-child(1){
                        color: #34495e;
                        font-size: 46px;
                        font-weight: 500;
                    }
                    >p:nth-child(2){
                        color: #999999;
                        font-size: 18px;
                        margin-top: -15px;
                    }
                }
                .terminalEquipmentBottom{
                    height: 100%;
                    .terminalLeft{
                        width: 50%;
                        height: 100%;
                        float: left;
                        .book{
                            width: 60%;
                            height: 70%;
                            background: url(../assets/images/index/book.png) no-repeat;
                            background-size: 100% 100%;
                            float: right;
                            max-height: 608px;
                            min-height: 434px;
                        }
                    }
                    .terminalRight{
                        width: 50%;
                        height: 100%;
                        float: left;
                        .tvMedia{
                            width: 460px;
                            vertical-align: top;
                            .tvMediaTop{
                                p{
                                    margin-bottom: 15px;
                                }
                                >p:nth-child(1){
                                    color: #34495e;
                                    font-size: 36px;
                                    font-weight: 500;
                                    text-align: center;
                                }
                                >p:nth-child(2){
                                    color: #999999;
                                    font-size: 18px;
                                    margin-top: -10px;
                                    margin-left: 35px;
                                    text-align: center;
                                }
                            }
                            .tvMediaContent{
                                width: 100%;
                                margin-top: 20px;
                                >ul{
                                    width: 100%;
                                    height: 100%;
                                    >li{
                                      width: 100%;
                                      display: block;
                                      padding:20px 0px;
                                      line-height: 1;
                                      >span:nth-child(1){
                                          margin-left:26%;
                                          margin-right:5%;
                                          image{
                                              width: 26px;
                                              height: 26px;
                                          }
                                      }
                                      >span:nth-child(2){
                                          color: #1e1f48;
                                          font-size: 20px;
                                          display: inline-block;
                                          vertical-align: middle;
                                      }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .section:nth-child(3){
            >div{
                height: 100%!important;
                vertical-align: top!important;
                .advertisingResourcesTitle{
                    margin-top:80px;
                    text-align: center;
                    p{
                        margin-bottom: 20px;
                    }
                    >p:nth-child(1){
                        color: #34495e;
                        font-size: 46px;
                        font-weight: 500;
                    }
                    >p:nth-child(2){
                        color: #999999;
                        font-size: 18px;
                        margin-top: -15px;
                    }
                }
                .advertisingResourcesContent{
                    max-width: 1200px;
                    height: 100%;
                    margin:0 auto;
                    margin-top:40px;
                    margin-bottom: 20px;
                    .advertisingMedia{
                        border:2px solid #e4e4e5;
                        width: 100%;
                        height: 300px;
                        overflow: hidden;
                        margin-top:20px;
                    }
                    .advertisingMediaLeft{
                        width: 25%;
                        height: 100%;
                        float: left;
                        border-right:1px solid #e4e4e5;
                        background: #F9F9Fa;
                        .advertisingMediaArea{
                            padding: 0px 20px;
                            p:nth-child(1){
                                font-size: 20px;
                                color: #666666;
                                margin-top: 20px;
                                margin-bottom: 10px;
                                font-weight: 500;
                            }
                            p:nth-child(2){
                                font-size: 14px;
                                color: #999899;
                            }
                        }
                        .advertisingMediaScene{
                            padding: 0px 20px;
                            p{
                                font-size: 18px;
                                color: #666666;
                                margin-top: 10px;
                                margin-bottom: 10px;
                                font-weight: 500;
                            }
                            >ul{
                                >li{
                                    width: 100%;
                                    font-size: 14px;
                                    color: #999999;
                                    padding: 3px 0px;
                                }
                            }
                        }
                    }
                    .advertisingMediaMiddle{
                        width: 50%;
                        height: 100%;
                        float: left;
                        padding: 20px;
                        border-right:1px solid #e4e4e5;
                        box-sizing: border-box;
                        .advertisingMediaInfo{
                            width: 50%;
                            height: 50%;
                            float: left;
                            >p:nth-child(1){
                                color: #666666;
                                font-size: 14px;
                            }
                            >p:nth-child(2){
                                margin-top: 20px;
                                span:nth-child(1){
                                    font-size: 25px;
                                    color:#ff9c38;
                                }
                                span:nth-child(2){
                                    font-size: 14px;
                                    color:#ff9c38;
                                }
                            }
                        }
                        .advertisingMediaInfo:nth-child(1),.advertisingMediaInfo:nth-child(2){
                            border-bottom: 1px solid #c6c6c6;
                        }
                        .advertisingMediaInfo:nth-child(3),.advertisingMediaInfo:nth-child(4){
                            padding-top: 20px;
                        }
                        .advertisingMediaInfo:nth-child(2),.advertisingMediaInfo:nth-child(4){
                            padding-left: 20px;
                        }
                        .advertisingMediaInfo:nth-child(2),.advertisingMediaInfo:nth-child(4){
                            border-left: 1px solid #c6c6c6;
                        }
                    }
                    .advertisingMediaRight{
                        width: 25%;
                        height: 100%;
                        float: left;
                        background: #F9F9Fa;
                        padding: 10px 20px;
                        .startDay,.endDay,.totalDay{
                            padding: 10px 0px;
                            >span{
                                color: #666666;
                                margin-right: 5px;
                            }
                            >input{
                                height: 32px;
                                padding-left: 10px;
                                border:1px solid #dddddd;
                            }
                            >input::-webkit-input-placeholder{
                                color: #999999;
                            }
                        }
                        .price{
                            margin-top: 10px;
                            >span:nth-child(1){
                                font-size: 25px;
                                color: #ff9c38;
                            }
                            >span:nth-child(2){
                                font-size: 14px;
                                color: #999999;
                                margin-left: 3px;
                            }
                        }
                        .priceDesc{
                            >span{
                                font-size: 14px;
                                color: #999999;
                                margin-top: 10px;
                            }
                        }
                        .immediateSales{
                            background: #4B56A5;
                            height: 36px;
                            line-height: 36px;
                            text-align: center;
                            margin-top: 8px;
                            button{
                                height: 36px;
                                line-height: 36px;
                                width: 100%;
                                color: #ffffff;
                                cursor: pointer;
                            }
                        }
                    }
                }
                .more{
                    width:182px;
                    height:35px;
                    margin: 0 auto;
                    margin-top: 25px;
                    background: url(../assets/images/index/more.png) no-repeat;
                    background-size: 100% 100%;
                    cursor: pointer;
                }
            }
        }
    }
    .section1{
        height: 68%;
        .zhotiMedia{
            width: 100%;
            height:100%;
            background-size: 100% 100%;
            img{
                width:100%;
                height: auto;
            }
        }
        
    }  
}
#fp-nav{
    display: none!important;
}

